/*********************************/
/*             Home            */
/*===============================*/
/* background-effect For Job Hero */
.background-effect {
    .circles {
        @apply absolute inset-0 h-full w-full overflow-hidden;
        li {
            @apply absolute block -bottom-[150px] bg-indigo-600/30;
            animation: animate 25s linear infinite;

            &.brand-img {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10) {
                    @apply bg-transparent;
                }
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10) {
                @apply w-12 h-12;
            }
            &:nth-child(1) {
                @apply left-1/4;
                animation-delay: 0s;
            }
        
            &:nth-child(2) {
                @apply left-[10%];
                animation-delay: 2s;
                animation-duration: 12s;
            }
        
            &:nth-child(3) {
                @apply left-[70%];
                animation-delay: 4s;
            }
        
            &:nth-child(4) {
                @apply left-[40%];
                animation-delay: 0s;
                animation-duration: 18s;
            }
        
            &:nth-child(5) {
                @apply left-[65%];
                animation-delay: 0s;
            }
        
            &:nth-child(6) {
                @apply left-3/4;
                animation-delay: 3s;
            }
        
            &:nth-child(7) {
                @apply left-[35%];
                animation-delay: 7s;
            }
        
            &:nth-child(8) {
                @apply left-1/2;
                animation-delay: 15s;
                animation-duration: 45s;
            }
        
            &:nth-child(9) {
                @apply left-[20%];
                animation-delay: 2s;
                animation-duration: 35s;
            }
        
            &:nth-child(10) {
                @apply left-[85%];
                animation-delay: 0s;
                animation-duration: 11s;
            }
        }
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 10px;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}


/* studio home */
.studio-wrapper {
    @apply before:content-[''] before:absolute before:bottom-[14rem] before:left-[50rem] before:w-[60rem] before:h-[30rem] before:rounded-[18rem] before:rotate-[135deg] before:bg-indigo-600/5 dark:before:bg-indigo-600/10;
}
@media (min-width: 1024px) and (max-width: 1140px) {
    .studio-wrapper {
        @apply before:bottom-[10rem] before:left-[30rem] before:w-[40rem] before:h-[20rem];
    }
}

@media (max-width: 768px) {
    .studio-wrapper {
        @apply before:left-[12rem];
    }
}

@media (max-width: 767px) {
    .studio-wrapper {
        @apply before:left-0;
    }
}

/* Startup Hero */
.startup-wrapper {
    @apply before:content-[''] before:absolute before:bottom-40 before:left-[45rem] before:w-[90rem] before:h-[35rem] before:rotate-[115deg] before:bg-indigo-600;

    @apply after:content-[''] after:absolute after:bottom-96 after:left-[30rem] after:w-[75rem] after:h-40 after:rotate-[115deg] after:bg-indigo-600/10 after:-z-1;
}

@media (min-width: 1024px) and (max-width: 1140px) {
    .startup-wrapper {
        @apply before:left-40 before:w-[75rem] before:h-[30rem] after:left-0 after:bottom-80 after:w-[75rem] after:h-60;
    }
}

@media (max-width: 768px) {
    .startup-wrapper {
        @apply before:left-40 after:left-0;
    }
}

@media (max-width: 767px) {
    .startup-wrapper {
        @apply before:left-0 after:-left-40;
    }
}

/* Kenburn Effect for Business Demo */
.image-wrap {
    animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
    0% {
        transform-origin: bottom;
        transform: scale(1);
    }
    100% {
        transform: scale(1.4);
    }
}

@keyframes ppb_kenburns {
    0% {
        transform: scale(1.3) translate(-10%, 10%);
    }
    25% {
        transform: scale(1) translate(0, 0);
    }
    50% {
        transform: scale(1.3) translate(10%, 10%);
    }
    75% {
        transform: scale(1) translate(0, 0);
    }
    100% {
        transform: scale(1.3) translate(-10%, 10%);
    }
}

/* Saas */
.home-wrapper {
    @apply before:content-[''] before:absolute before:-top-[30%] before:-left-80 before:right-0 before:w-[140rem] before:h-[65rem] before:-rotate-12 before:bg-indigo-600/5 dark:before:bg-indigo-600/10 before:z-1;
}

@media (min-width: 769px) and (max-width: 1024px) {
    .home-wrapper {
        @apply before:-top-[50%];
    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .home-wrapper {
        @apply before:-top-[80%];
    }
}
@media (max-width: 425px) {
    .home-wrapper {
        @apply before:-top-[90%];
    }
}